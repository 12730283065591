
import { computed, defineComponent, ref } from "vue";
import { hideModal } from "@/core/helpers/dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { useStore } from "vuex";
import { StepResult } from "@/store/captain/CaptainModule";
import CourseSelector from "@/views/course/selector/Course.vue";
import { CourseActions } from "@/store/vm/course/enums";

export default defineComponent({
  name: "insert-course-cate-detail",
  components: { CourseSelector },
  props: {
    courseCateDetailId: {
      type: [Number, Boolean],
      required: true,
      default: false,
    },
  },
  emits: ["insertSuccess"],
  setup(props, { emit }) {
    const store = useStore();

    const courseDetailInsertToCate = ref<null | HTMLElement>(null);
    const loading = ref<boolean>(false);

    const courseIdTarget = ref(0);

    const modalInsertCourseId = computed(() => {
      return store.state.CourseCateModule.modalInsertCourseId;
    });

    const actionInsert = async () => {
      const courseCateId = props.courseCateDetailId;
      const courseId = courseIdTarget.value;

      return await store
        .dispatch(CourseActions.ADD_COURSE_TO_CATE, {
          courseCateId,
          courseId,
        })
        .catch((e) => {
          console.log(e);
        });
    };

    const submit = async () => {
      loading.value = true;

      let stepResult: StepResult;
      stepResult = await actionInsert();

      loading.value = false;
      if (stepResult.isSuccess) {
        hideModal(courseDetailInsertToCate.value);
        Swal.fire({
          position: "top-end",
          text: "Success!",
          icon: "success",
          buttonsStyling: false,
          timer: 2000,
          width: 400,
          showCancelButton: false,
          showConfirmButton: false,
        });
        emit("insertSuccess");
      } else {
        Swal.fire({
          position: "top-end",
          text: stepResult["data"] || "Đã xảy ra lỗi, vui lòng thử lại sau!",
          icon: "error",
          buttonsStyling: false,
          timer: 2000,
          width: 400,
          showCancelButton: false,
          showConfirmButton: false,
        });
      }
    };

    const onChange = (payload) => {
      if (payload.name === "courseId") {
        courseIdTarget.value = payload.value;
      }
    };

    return {
      courseDetailInsertToCate,
      modalInsertCourseId,
      submit,
      loading,
      onChange,
      courseIdTarget,
    };
  },
});
