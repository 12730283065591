
import { CourseCateActions } from "@/store/vm/course-cate/enums";
import { computed, defineComponent, onMounted, ref } from "vue";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";
import moment from "moment";
import { useRoute } from "vue-router";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { MenuComponent } from "@/assets/ts/components";
import UpdateCourseCateDetail from "@/views/course-cate/detail/Update.vue";
import InsertCourseCateDetail from "@/views/course-cate/detail/Insert.vue";
import Swal from "sweetalert2/dist/sweetalert2.js";

export default defineComponent({
  name: "courseCate-detail",
  components: {
    InsertCourseCateDetail,
    UpdateCourseCateDetail,
  },
  props: {
    widgetClasses: String,
  },
  emits: ["upsert", "del"],
  data() {
    return {
      loadingModule: true,
    };
  },
  setup() {
    const store = useStore();
    const checked = ref(false);
    const { t } = useI18n();
    const route = useRoute();

    const courseCateId = parseInt(route.params["courseCateId"] as any);
    const cateName = ref("");
    let list = ref([]);
    const page = ref(1);

    const courseCateDetailIdTarget = ref<boolean | number>(false);

    const getListDetail = async () => {
      const response = await store
        .dispatch(CourseCateActions.GET_ALL_COURSE_BY_CATE_ID, {
          page: page.value,
          id: courseCateId,
        })
        .catch((e) => {
          console.log(e);
        });
      list.value = response;
    };

    onMounted(async () => {
      setCurrentPageBreadcrumbs("Chi tiết", [
        "Đào tạo",
        "Danh mục chương trình học",
      ]);
      MenuComponent.reinitialization();

      const detailCate = await store
        .dispatch(CourseCateActions.GET_DETAIL, courseCateId)
        .catch((e) => {
          console.log(e);
        });
      cateName.value = detailCate.name;

      await getListDetail();
    });

    const count = computed(() => {
      return list.value.length;
    });

    const updateCourseCateDetail = (courseCateDetailId) => {
      courseCateDetailIdTarget.value = courseCateDetailId;
    };

    const delCourseCateDetail = (courseCateDetailId) => {
      Swal.fire({
        title: "Bạn muốn xóa chương trình học này khỏi danh mục?",
        text: "",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      }).then(async (result) => {
        if (result.isConfirmed) {
          const resDel = await actionDelete(courseCateDetailId);
          if (resDel["isSuccess"]) {
            await getListDetail();
          }
        }
      });
    };

    const actionDelete = async (courseCateDetailId) => {
      return store
        .dispatch(CourseCateActions.REMOVE_COURSE_FROM_CATE, courseCateDetailId)
        .catch((e) => {
          console.log(e);
        });
    };

    const modalUpdatePointId = store.state.CourseCateModule.modalUpdatePointId;
    const modalInsertCourseId =
      store.state.CourseCateModule.modalInsertCourseId;

    const lang = {
      edit: t("common.edit"),
      delete: t("common.delete"),
    };

    const onUpdateSuccess = async () => {
      await getListDetail();
    };

    return {
      list,
      cateName,
      checked,
      count,
      getListDetail,
      updateCourseCateDetail,
      onUpdateSuccess,
      delCourseCateDetail,
      modalUpdatePointId,
      modalInsertCourseId,
      courseCateDetailIdTarget,
      lang,
      moment,
      page,
      courseCateId,
    };
  },
});
